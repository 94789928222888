import React, { useEffect, useState } from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Sidebar from '../../Components/Sidebar/Sidebar'
import './restaurant.css'
import { MDBBadge, MDBBtn, MDBTable, MDBTableHead, MDBTableBody, MDBContainer, MDBInput, MDBCardText, MDBCard, MDBCol, MDBRow, MDBCardBody, MDBListGroup, MDBListGroupItem, MDBIcon, MDBCardImage, MDBSwitch } from 'mdb-react-ui-kit';
import AddRestaurantModal from './modal/AddRestaurantModal';
import { useLocation, useNavigate } from 'react-router-dom';
import TableCell, { AddTableCell } from './TableCell/TableCell';
import { fetchAllStaffsByRestaurant } from '../../apis/staffRequests';
import AddSectionModal from './modal/AddSectionsModal';
import EditRestaurantModal from './modal/EditRestaurantModal';
import AddStaffModal from './modal/AddStaffDialog';
import EditStaffModal from './modal/EditStaffDialog.jsx';
import ViewStaffModal from './modal/ViewStaffModal';
import { RiDeleteBin7Fill, RiPenNibFill, RiPencilFill } from 'react-icons/ri';
import { updateRestaurant } from '../../apis/restaurantRequests';
import { toast } from 'react-toastify';
import AddTableModal from './modal/AddTableModal';
import UpdateRestaurantImage from './modal/UpdateRestaurantImage';
import { fabClasses } from '@mui/material';
import EditSectionModal from './modal/EditSectionDialog';

export default function RestaurantView() {
    const [editRestaurantModalOpen, setEditRestaurantModalOpen] = useState(false)
    const [isUpdateImageOpen, setIsUpdateImageOpen] = useState(false)
    const [addSectionModalOpen, setAddSectionModalOpen] = useState(false)
    const [restaurants, setRestaurants] = useState([])
    const [addStaffDialogOpen, setAddStaffDialogOpen] = useState(false)
    const [editStaffDialogOpen, setEditStaffDialogOpen] = useState(false)
    const [captainCanBill, setCaptainCanBill] = useState(false)
    const [viewStaff, setViewStaff] = useState({})
    const [editStaff, setEditStaff] = useState({})
    const [staffs, setStaffs] = useState([])
    const location = useLocation()
    const restaurant = location.state;
    const navigate = useNavigate()
    const [viewStaffDialogOpen, setViewStaffDialogOpen] = useState(false)
    const [isAddTableDialogOpen, setIsAddTableDialogOpen] = useState(false)

    useEffect(() => {
        (async function () {
            if (restaurant?._id) {
                const { data } = await fetchAllStaffsByRestaurant(restaurant._id)
                setStaffs([...data.staffs])
            }
        })()
        if (restaurant) {
            setCaptainCanBill(restaurant?.captainCanBill ?? false)
        }
    }, [restaurant])

    const handleDeleteSection = async (index) => {
        const sections = restaurant.sections.filter((item, id) => index != id);
        const res = await updateRestaurant(restaurant._id, { sections })
        navigate("/restaurants")
    }
    const handleSaveCaptainPermission = async () => {
        let res = await updateRestaurant(restaurant._id, { captainCanBill });
        if (res.data) {
            navigate("/restaurants")
        }
    }
    const [editSection, setEditSection]= useState({})
    const [editSectionDialog, setEditSectionDialog]= useState(false)
    const [addDialogSection, setAddDialogSection] = useState("")
    const restaurantDummyImg = "https://thumbs.dreamstime.com/z/restaurant-building-flat-design-architecture-facade-food-cafe-construction-vector-illustration-69417405.jpg"
    return (
        <>
            <Navbar></Navbar>
            <div className="main-layout">
                <Sidebar></Sidebar>
                <div className="main-body">
                    <MDBContainer fluid>
                        <div className='main-body-container'>
                            <div className='main-body-table-heading'>
                                <h5>Restaurant Details</h5>
                            </div>
                            <div className='main-body-table-header'>
                                <div className="main-body-table-header-sec-1">
                                    {/* <MDBInput label='Example label' id='form1' type='text' /> */}
                                </div>
                                <div className="main-body-table-header-sec-2">
                                    <MDBBtn className='primary-btn rounded-6' onClick={() => setEditRestaurantModalOpen(true)}>Edit Restaurant</MDBBtn>
                                </div>
                            </div>
                            <MDBRow>
                                <MDBCol lg="4">
                                    <MDBCard className="mb-4">
                                        <MDBCardBody className="text-center d-flex flex-column align-items-center">
                                            <MDBCardImage
                                                onClick={() => setIsUpdateImageOpen(true)}
                                                src={restaurant.image ?? restaurantDummyImg}
                                                alt="avatar"
                                                className="rounded-circle mb-3"
                                                style={{ width: '180px', height: "180px", objectFit: "contain" }}
                                                fluid />
                                            <b className="text-muted mb-1">{restaurant?.name?.toUpperCase()}</b>
                                            {/* <p className="text-muted mb-4">Bay Area, San Francisco, CA</p> */}
                                            {/* <div className="d-flex justify-content-center mb-2">
                                                <MDBBtn>Follow</MDBBtn>
                                                <MDBBtn outline className="ms-1">Message</MDBBtn>
                                            </div> */}
                                        </MDBCardBody>
                                    </MDBCard>

                                </MDBCol>
                                <MDBCol lg="8">
                                    <MDBCard className="mb-4">
                                        <MDBCardBody>
                                            <MDBRow>
                                                <MDBCol sm="3">
                                                    <MDBCardText>Name</MDBCardText>
                                                </MDBCol>
                                                <MDBCol sm="9">
                                                    <MDBCardText className="text-muted">{restaurant?.name ?? "--"}</MDBCardText>
                                                </MDBCol>
                                            </MDBRow>
                                            <hr />
                                            <MDBRow>
                                                <MDBCol sm="3">
                                                    <MDBCardText>Description</MDBCardText>
                                                </MDBCol>
                                                <MDBCol sm="9">
                                                    <MDBCardText className="text-muted">{restaurant?.description ?? "--"}</MDBCardText>
                                                </MDBCol>
                                            </MDBRow>
                                            <hr />
                                            <MDBRow>
                                                <MDBCol sm="3">
                                                    <MDBCardText>Opening time</MDBCardText>
                                                </MDBCol>
                                                <MDBCol sm="9">
                                                    <MDBCardText className="text-muted">{restaurant?.openingTime ?? "--"}</MDBCardText>
                                                </MDBCol>
                                            </MDBRow>
                                            <hr />
                                            <MDBRow>
                                                <MDBCol sm="3">
                                                    <MDBCardText>Closing Time</MDBCardText>
                                                </MDBCol>
                                                <MDBCol sm="9">
                                                    <MDBCardText className="text-muted">{restaurant?.closingTime ?? "--"}</MDBCardText>
                                                </MDBCol>
                                            </MDBRow>
                                            <hr />
                                            <MDBRow>
                                                <MDBCol sm="3">
                                                    <MDBCardText>Captain Can bill</MDBCardText>
                                                </MDBCol>
                                                <MDBCol sm="1">
                                                    <MDBCardText className="text-muted">
                                                        <MDBSwitch checked={captainCanBill} onChange={(e) => {
                                                            setCaptainCanBill(e.target.checked)
                                                        }} id='flexSwitchCheckDefault' value label='' />
                                                    </MDBCardText>
                                                </MDBCol>
                                                <MDBCol sm="8">
                                                    <MDBCardText className="text-muted">
                                                        {
                                                            (restaurant?.captainCanBill !== captainCanBill) &&
                                                            <MDBBtn onClick={handleSaveCaptainPermission} size='sm' className='rounded-8' color='secondary'>Save</MDBBtn>
                                                        }
                                                    </MDBCardText>
                                                </MDBCol>
                                            </MDBRow>

                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                            </MDBRow>
                            <div className='main-body-table-header'>
                                <div className="main-body-table-header-sec-1">
                                    <h5 >Staffs</h5>
                                </div>
                                <div className="main-body-table-header-sec-2">
                                    <MDBBtn className='primary-btn rounded-6' onClick={() => setAddStaffDialogOpen(true)}>Add Staff</MDBBtn>
                                </div>
                            </div>
                            <div className='table-responsive shadow-4'>
                                <MDBTable className='bg-white ' align='middle'>
                                    <MDBTableHead>
                                        <tr>
                                            <th scope='col'>Name</th>
                                            <th scope='col'>Email</th>
                                            <th scope='col'>Role </th>
                                            <th scope='col'>Actions</th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {
                                            (staffs ?? []).map((item, index) => {
                                                return <tr onClick={() => {
                                                    setViewStaff({ ...item })
                                                    setViewStaffDialogOpen(true)
                                                }} className='restaurant-table-row'>

                                                    <td>
                                                        <div className='d-flex align-items-center'>

                                                            <b className='fw-bold mb-1'>{item.name}</b>
                                                            {/* <p className='text-muted mb-0'>john.doe@gmail.com</p> */}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className='fw-normal mb-1'>{item.email}</p>
                                                        {/* <p className='text-muted mb-0'>IT department</p> */}
                                                    </td>
                                                    <td>
                                                        {/* <MDBBadge color='success' pill>
                                                        Active
                                                    </MDBBadge> */}
                                                        <p className='fw-normal mb-1'>{item.role}</p>
                                                    </td>

                                                    <td>
                                                        <MDBBtn color='secondary' onClick={(e) => {
                                                            e.stopPropagation()
                                                            setEditStaff({ ...item })
                                                            setEditStaffDialogOpen(true)
                                                        }} rounded size='sm'>
                                                            Edit
                                                        </MDBBtn>
                                                    </td>
                                                </tr>
                                            })
                                        }

                                    </MDBTableBody>
                                </MDBTable>
                            </div>
                            <div className='main-body-table-header'>
                                <div className="main-body-table-header-sec-1">
                                    <h5>Sections</h5>
                                </div>
                                <div className="main-body-table-header-sec-2">
                                    <MDBBtn className='primary-btn rounded-6' onClick={() => setAddSectionModalOpen(true)}>Add Sections</MDBBtn>
                                </div>
                            </div>
                            <div className="section-table-listing">
                                {
                                    restaurant?.sections?.map((section, index) => {
                                        return <>
                                            <div className="section-table-listing-head">

                                                <b>{section.name}</b>
                                                <MDBBtn color='secondary' onClick={() => handleDeleteSection(index)} rounded size='sm'>
                                                    <RiDeleteBin7Fill />
                                                </MDBBtn>
                                                <MDBBtn color='secondary' onClick={(e) => {
                                                    e.stopPropagation()
                                                    setEditSection({ ...section, index })
                                                    setEditSectionDialog(true)
                                                }} rounded size='sm'>
                                                    <RiPencilFill />
                                                </MDBBtn>
                                            </div>
                                            <div className="section-table-listing-container">
                                                {
                                                    section?.tables?.map((item, index) => {
                                                        return <TableCell key={index} table={item} />
                                                    })
                                                }
                                                <AddTableCell
                                                    callback={() => {
                                                        setIsAddTableDialogOpen(true);
                                                        setAddDialogSection(section.name);
                                                    }}
                                                />
                                            </div>
                                        </>
                                    })
                                }


                            </div>

                        </div>

                    </MDBContainer>


                </div>
                <AddSectionModal
                    open={addSectionModalOpen}
                    setOpen={setAddSectionModalOpen}
                    setRestaurants={setRestaurants}
                    restaurant={restaurant}
                    staffs={staffs}
                    />
                <EditSectionModal
                    staffs={staffs}
                    open={editSectionDialog}
                    setOpen={setEditSectionDialog}
                    restaurant={restaurant}
                    data={editSection}
                />
                <EditRestaurantModal
                    open={editRestaurantModalOpen}
                    setOpen={setEditRestaurantModalOpen}
                    setRestaurants={setRestaurants}
                    restaurant={restaurant}
                />
                <AddStaffModal
                    open={addStaffDialogOpen}
                    setOpen={setAddStaffDialogOpen}
                    setRestaurants={setRestaurants}
                    restaurant={restaurant}
                />
                <EditStaffModal
                    open={editStaffDialogOpen}
                    setOpen={setEditStaffDialogOpen}
                    setRestaurants={setRestaurants}
                    staff={editStaff}
                />
                <ViewStaffModal
                    open={viewStaffDialogOpen}
                    setOpen={setViewStaffDialogOpen}
                    setRestaurants={setRestaurants}
                    staff={viewStaff}
                />
                <AddTableModal
                    open={isAddTableDialogOpen}
                    setOpen={setIsAddTableDialogOpen}
                    setRestaurants={setRestaurants}
                    restaurant={restaurant}
                    section={addDialogSection}
                />
                <UpdateRestaurantImage
                    image={restaurant.image}
                    restaurantId={restaurant._id}
                    open={isUpdateImageOpen}
                    setOpen={setIsUpdateImageOpen}
                />
            </div>
        </>
    )
}
