import React, { useEffect, useState } from "react";
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
    MDBTextArea,
} from "mdb-react-ui-kit";
import { RiAddCircleFill, RiDeleteBin7Fill } from "react-icons/ri";
import { createRestaurant, updateRestaurant } from "../../../apis/restaurantRequests";
import { useNavigate } from "react-router-dom";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export default function EditRestaurantModal({ open, setOpen, setRestaurants, setRefresh, restaurant }) {
    const toggleShow = () => setOpen(!open);
    const navigate = useNavigate()
    const [restaurantDetails, setRestaurantDetails] = useState({
        name: "",
        description: "",
        openingTime: "",
        closingTime: "",
        latitude: null,
        longitude: null,
        taxes: [],
        upiUrl:"",
        reviewUrl:"",
        gstNo:""
    });
    const [tax, setTax] = useState({
        name: "",
        value: null,
        type: "percentage",
    });

    const addTax = () => {
        if (!tax.name || !tax.value) {
            return;
        }
        setRestaurantDetails({
            ...restaurantDetails,
            taxes: [...restaurantDetails.taxes, tax],


        });
        setTax({
            name: "",
            value: 0,
            type: "percentage",
        });
    };

    const handleDeleteTax = (index) => {
        const newTaxes = restaurantDetails.taxes.filter((t, i) => index != i);
        setRestaurantDetails({ ...restaurantDetails, taxes: [...newTaxes] });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value)
        setRestaurantDetails({
            ...restaurantDetails,
            [name]: value
        })
    }

    const validateForm = () => {
        if (restaurantDetails.name.trim() === "" ||
            !restaurantDetails.openingTime ||
            !restaurantDetails.closingTime
        ) {
            return false;
        }
        return true
    }

    const handleSave = async (e) => {
        e.preventDefault()
        if (!validateForm()) return;
        const { data } = await updateRestaurant(restaurant._id, {
            ...restaurantDetails, location: {
                latitude: restaurantDetails.latitude,
                longitude: restaurantDetails.longitude
            }
        })
        navigate("/restaurants")
        setOpen(false)
    }

    useEffect(() => {
        if (restaurant) {
            setRestaurantDetails({
                name: restaurant?.name ?? "",
                openingTime: restaurant?.openingTime ?? "",
                closingTime: restaurant?.closingTime ?? "",
                description: restaurant?.description ?? "",
                latitude: restaurant?.location?.latitude ?? null,
                longitude: restaurant?.location?.longitude ?? null,
                taxes: restaurant?.taxes ?? [],
                upiUrl: restaurant?.upiUrl ?? "",
                reviewUrl: restaurant?.reviewUrl ?? "",
                gstNo: restaurant?.gstNo ?? "",
            });
        }

    }, [restaurant])

    return (
        <>
            <MDBModal show={open} setShow={setOpen} tabIndex="-1">
                <MDBModalDialog size="lg" centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Edit Restaurant</MDBModalTitle>
                            <MDBBtn
                                className="btn-close"
                                color="none"
                                onClick={toggleShow}
                            ></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody className="add-restaurant-modal-body">
                            <div className="add-restaurant-modal-body-row">
                                <MDBInput
                                    name="name"
                                    value={restaurantDetails.name}
                                    onChange={handleChange}
                                    label="Restaurant Name"
                                    size="lg"
                                    id="form1"
                                    type="text"
                                />
                            </div>
                            <div className="add-restaurant-modal-body-row">
                                <MDBTextArea
                                    name="description"
                                    value={restaurantDetails.description}
                                    onChange={handleChange}
                                    label="Description"
                                    id="textAreaExample"
                                    rows={4}
                                />
                            </div>
                            <div className="add-restaurant-modal-body-row">
                                <MDBInput
                                    name="openingTime"
                                    value={restaurantDetails.openingTime}
                                    onChange={handleChange}
                                    label="Opening time"
                                    size="lg"
                                    id="form1"
                                    type="time"
                                />
                                <MDBInput
                                    name="closingTime"
                                    value={restaurantDetails.closingTime}
                                    onChange={handleChange}
                                    label="Closing time"
                                    size="lg"
                                    id="form1"
                                    type="time"
                                />
                            </div>
                            <div className="add-restaurant-modal-body-row">
                                <MDBInput
                                    name="latitude"
                                    value={restaurantDetails.latitude}
                                    onChange={handleChange}
                                    label="Latitude"
                                    size="lg"
                                    id="form1"
                                    type="number"
                                />
                                <MDBInput
                                    name="longitude"
                                    value={restaurantDetails.longitude}
                                    onChange={handleChange}
                                    label="Longitude"
                                    size="lg"
                                    id="form1"
                                    type="number"
                                />
                            </div>
                            <div className="add-restaurant-modal-body-row">
                                <MDBInput
                                    name="gstNo"
                                    value={restaurantDetails.gstNo}
                                    onChange={handleChange}
                                    label="GST No"
                                    size="lg"
                                    id="form1"
                                    type="text"
                                />
                            </div>
                            <div className="add-restaurant-modal-body-row">
                                <MDBInput
                                    name="upiUrl"
                                    value={restaurantDetails.upiUrl}
                                    onChange={handleChange}
                                    label="UPI URL"
                                    size="lg"
                                    id="form1"
                                    type="text"
                                />
                            </div>
                            <div className="add-restaurant-modal-body-row">
                                <MDBInput
                                    name="reviewUrl"
                                    value={restaurantDetails.reviewUrl}
                                    onChange={handleChange}
                                    label="Review URL"
                                    size="lg"
                                    id="form1"
                                    type="text"
                                />
                            </div>
                            <div className="add-restaurant-modal-body-row">
                                <b>Tax</b>
                            </div>
                            {restaurantDetails.taxes.map((t, index) => {
                                return (
                                    <div className="add-restaurant-modal-body-row">
                                        <MDBInput
                                            value={t.name}
                                            label="Tax name"
                                            disabled
                                            disabledlabel="Tax name"
                                            type="text"
                                        />
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={t.type}
                                                name="role"
                                                label="Type"
                                                disabled
                                            // onChange={handleRoleChange}
                                            >
                                                <MenuItem value={"percentage"}>Percentage</MenuItem>
                                                <MenuItem value={"fixed"}>Fixed</MenuItem>


                                            </Select>
                                        </FormControl>
                                        <MDBInput
                                            label="Value"
                                            value={t.value}
                                            disabled
                                            type="text"
                                        />
                                        <MDBBtn
                                            color="light"

                                            onClick={() => handleDeleteTax(index)}
                                        >
                                            <RiDeleteBin7Fill style={{ fontSize: "1.1rem" }} />
                                        </MDBBtn>
                                    </div>
                                );
                            })}
                            <div className="add-restaurant-modal-body-row">
                                <MDBInput
                                    value={tax.name}
                                    onChange={(e) => {
                                        setTax({ ...tax, name: e.target.value });
                                    }}
                                    label="Tax name"
                                    type="text"
                                />
                                <FormControl size="small" fullWidth>
                                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="role"
                                        label="Type"
                                        value={tax.type}
                                        onChange={(e) => setTax({ ...tax, type: e.target.value, value: 0 })}
                                    >
                                        <MenuItem value={"percentage"}>Percentage</MenuItem>
                                        <MenuItem value={"fixed"}>Fixed</MenuItem>
                                    </Select>
                                </FormControl>
                                <MDBInput
                                    label="Value"
                                    value={tax.value}
                                    onChange={(e) => {
                                        setTax({
                                            ...tax,
                                            value:
                                                tax.type === "percentage" ?
                                                    (e.target.value > 100
                                                        ? 100
                                                        : e.target.value < 0
                                                            ? 0
                                                            : e.target.value) :
                                                    e.target.value
                                        });
                                    }}
                                    type="number"
                                />
                                <MDBBtn
                                    color="secondary"
                                    disabled={!tax.name || !tax.value}
                                    onClick={addTax}
                                >
                                    <RiAddCircleFill style={{ fontSize: "1.1rem" }} />
                                </MDBBtn>
                            </div>
                        </MDBModalBody>

                        <MDBModalFooter>
                            <MDBBtn
                                className="rounded-6"
                                color="secondary"
                                onClick={toggleShow}
                            >
                                Close
                            </MDBBtn>
                            <MDBBtn onClick={handleSave} disabled={!validateForm()} className="rounded-6">Save</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
}
