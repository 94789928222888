const initialState={
    admin:{login:null},
    refresh:true
}

export default function authReducer(state=initialState, action){
    switch(action.type){
        case 'set-admin': return {...state, admin:action.payload};
        case 'admin-logout': {
            localStorage.removeItem('token');
            return {...state, admin:{login:false}};
        }
        case 'refresh-admin': {
            return {...state, refresh:!state.refresh};
        }
        default: return state;
    }
}