import React from 'react'
import {LuLayoutDashboard} from 'react-icons/lu'
import './Sidebar.css'
import {IoRestaurantOutline} from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { RiLogoutBoxRLine } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'

export default function Sidebar() {
    const currentPage=window.location.pathname.split("/")[1]
    const {open} = useSelector(state=>state.sidebar)
    const dispatch = useDispatch()
    return (
        <div className={`sidebar ${open ? "open" : "close"}`}>
            <div className="sidebar-container">
                <div className="sidebar-header">
                    <div className='sidebar-header-img'>
                        <img src="https://cashier.shakespeare.vision/static/media/shakespeare_logo.9d6d261255ed2e0f8e9e.png" alt="" />
                    </div>
                    <h6>Shakespeare</h6>
                </div>
                <Link to="/" onClick={()=>dispatch({type:'toggle'})} className={`sidebar-item ${currentPage==="" ? "active" : ""}`}>
                    <div className="sidebar-item-icon">
                        <LuLayoutDashboard className='side-item-icon' />
                    </div>
                    <div className="sidebar-item-name">
                        Dashboard
                    </div>
                </Link>
                <Link to="/restaurants" onClick={()=>dispatch({type:'toggle'})} className={`sidebar-item ${currentPage==="restaurants" ? "active" : ""}`}>
                    <div className="sidebar-item-icon">
                        <IoRestaurantOutline className='side-item-icon' />
                    </div>
                    <div className="sidebar-item-name">
                        Restaurants
                    </div>
                </Link>
                <div className={`sidebar-item mt-5 `}
                onClick={()=>{
                    localStorage.clear();
                    window.location.reload()
                }}>
                    <div className="sidebar-item-icon">
                        <RiLogoutBoxRLine className='side-item-icon' />
                    </div>
                    <div className="sidebar-item-name">
                        Logout
                    </div>
                </div>
            </div>
        </div>
    )
}
