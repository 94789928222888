import React, { useEffect } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Restaurants from './Pages/Restaurants/Restaurants'
import Dashboard from './Pages/Dashboard/Dashboard'
import { useDispatch, useSelector } from 'react-redux'
import Login from './Pages/Login/Login'
import axios from 'axios'
import toast from './utils/toast'
import RestaurantView from './Pages/Restaurants/RestaurantView'

export default function App() {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("token");

  const { refresh, admin } = useSelector((state) => state.auth);
  const dispatch = useDispatch()

  useEffect(() => {
    (async function () {
      try {
        let res = await axios.get("/admin/auth/login/check");
        dispatch({ type: "set-admin", payload: { login: true } })
      } catch (err) {
        console.log(err)
        dispatch({ type: "set-admin", payload: { login: false } })
        toast.error("please login")
      }
    })()
  }, [refresh])

  console.log("app running... 2.0")

  return (
    <div className="App">
      {
        admin.login &&
        <Routes>
          <Route path='/' element={<Dashboard />} />
          <Route path='/restaurants' element={<Restaurants />} />
          <Route path='/restaurants/view' element={<RestaurantView />} />
          <Route path='/login' element={<Navigate to="/restaurants" />} />
        </Routes>
      }
      {
        admin.login === false &&
        <Routes>
          <Route path='/' element={<Navigate to="/login" />} />
          <Route path='/restaurants' element={<Navigate to="/login" />} />
          <Route path='/restaurants/view' element={<Navigate to="/login" />} />
          <Route path='/login' element={<Login />} />
        </Routes>
      }
    </div>
  )
}
