import React, { useEffect, useState } from "react";
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
    MDBTextArea,
} from "mdb-react-ui-kit";
import { RiAddCircleFill, RiDeleteBin7Fill } from "react-icons/ri";
import { createRestaurant, createSection, updateRestaurant } from "../../../apis/restaurantRequests";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function EditSectionModal({ open, setOpen, data, restaurant, staffs }) {
    const toggleShow = () => setOpen(!open);
    const [cashier, setCashier] = useState('')
    const navigate = useNavigate()

    const [section, setSection] = useState({
        name: "",
        noOfTables: null,
        type: "dinein"
    });

    const validateForm = () => {
        if (!section.name || !cashier || !section.type) {
            return false;
        }
        return true
    }

    const handleSave = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        const sectionsData = restaurant.sections;
        sectionsData[data.index]= {
            ...section,
            cashier
        }
        const res = await updateRestaurant(restaurant._id, { sections:sectionsData })
        if (res.data) {
            navigate("/restaurants")
            setOpen(false)
        }
    }
    useEffect(()=>{
        if(data){
            setSection({
                ...data
            })
            setCashier(data.cashier)
        }
    },[data])


    return (
        <>
            <MDBModal show={open} setShow={setOpen} tabIndex="-1">
                <MDBModalDialog size="md" centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Edit Section</MDBModalTitle>
                            <MDBBtn
                                className="btn-close"
                                color="none"
                                onClick={toggleShow}
                            ></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody className="add-restaurant-modal-body">

                            <div className="add-restaurant-modal-body-row">
                                <b>Sections</b>
                            </div>
                            <div className="add-restaurant-modal-body-row">
                                <FormControl size="small" fullWidth>
                                    <InputLabel id="demo-simple-select-label">Cashier</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={cashier}
                                        label="Cashier"
                                        onChange={(e) => setCashier(e.target.value)}
                                    >
                                        {
                                            staffs?.filter(staff => staff.role === "CASHIER").map((staff, index) => {
                                                return <MenuItem value={staff._id}>{staff.name}</MenuItem>
                                            })
                                        }

                                    </Select>
                                </FormControl>
                            </div>
                           
                            <div className="add-restaurant-modal-body-row">
                                <MDBInput
                                    value={section.name}
                                    onChange={(e) => {
                                        setSection({ ...section, name: e.target.value });
                                    }}
                                    label="Section name"
                                    type="text"
                                />
                            </div>
                            <div className="add-restaurant-modal-body-row">

                                <FormControl size="small" fullWidth>
                                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={section.type}
                                        label="Type"
                                        onChange={(e) => setSection({ ...section, type: e.target.value })}
                                    >
                                        <MenuItem value={"dinein"}>Dine In</MenuItem>
                                        <MenuItem value={"takeaway"}>Takeaway</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>


                    </MDBModalBody>

                    <MDBModalFooter>
                        <MDBBtn
                            className="rounded-6"
                            color="secondary"
                            onClick={toggleShow}
                        >
                            Close
                        </MDBBtn>
                        <MDBBtn onClick={handleSave} disabled={!validateForm()} className="rounded-6">Save</MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal >
        </>
    );
}
