import axios from "axios";
import toast from "../utils/toast";

export const fetchAllStaffsByRestaurant = async (id) => {
  try {
    const res = await axios.get(`/restaurants/${id}/staffs`);

    return res;
  } catch (error) {
    console.log(error);
    toast.error(error.response?.data?.message ??"Network connection failed");
    return { data: { staffs: [] } };
  }
};
export const createStaff = async (id, data) => {
  try {
    const res = await axios.post(`/admin/restaurants/${id}/staff`, data);
    toast.success("Staff created");

    return res;
  } catch (error) {
    console.log(error);
    toast.error(error.response?.data?.message ??"Network connection failed");
    return { data: { err: true } };
  }
};

export const updateStaff = async (id, data) => {
  try {
    const res = await axios.put(`/restaurants/staff/update/${id}`, data);
    toast.success("Staff updated");
    return res;
  } catch (error) {
    console.log(error);
    toast.error(error.response?.data?.message ??"Network connection failed");
    return { data: { err: true } };
  }
};
