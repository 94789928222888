import axios from "axios";
import toast from "../utils/toast";

export const fetchAllRestaurants=async ()=>{
    try{
        const res = await axios.get("/admin/restaurants");
        return res
    }catch(error){
        console.log(error)
            toast.error(error.response?.data?.message ?? "Network connection failed");
            return {data:{}}
    }
}
export const createRestaurant=async (data)=>{
    try{
        const res = await axios.post("/admin/restaurants/create", data);
        toast.success("Restaurant created");
        return res
    }catch(error){
        console.log(error)
            toast.error(error.response?.data?.message ?? "Network connection failed");
            return {data:{}}
    }
}
export const updateRestaurant=async (restaurandId,data)=>{
    try{
        const res = await axios.put(`/admin/restaurants/${restaurandId}`, data);
        toast.success("Restaurant updated");
        return res
    }catch(error){
        console.log(error)
            toast.error(error.response?.data?.message ?? "Network connection failed");
            return {err:true, error}
    }
}
export const createSection=async (restaurandId, data)=>{
    try{
        console.log(data)
        const res = await axios.post(`/admin/restaurants/${restaurandId}/sections`,data);
        toast.success("Section created");
        console.log(res)
        return res
    }catch(error){
        console.log(error)
            toast.error(error.response?.data?.message ?? "Network connection failed");
            return {data:null}
    }
}
export const createTable=async (restaurandId, tableName, sectionName)=>{
    try{
        const res =  await axios.post(
            `/restaurants/${restaurandId}/tables`,
            {
                name: tableName,
                section: sectionName,
                isTemporary: false,
            });
        toast.success("Table created");
        console.log(res)
        return res
    }catch(error){
        console.log(error)
            toast.error(error.response?.data?.message ?? "Network connection failed");
            return {}
    }
}
export const updateRestaurantImage=async (restaurandId, imageToUpload)=>{
    try{
        const formData = new FormData();
		formData.append("image", imageToUpload);
        console.log(formData.get('image'), imageToUpload)
        const res = await axios.put(
            `/admin/restaurants/${restaurandId}/image`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "x-auth-token": localStorage.getItem("token"),
                },
            }
        );
        console.log(res)
        
        toast.success("Image uploaded successfully");
        return res
    }catch(error){
        console.log(error)
            toast.error(error.response?.data?.message ?? "Network connection failed");
            return {err:true}
    }
}
