import React from 'react'
import './navbar.css'
import { MDBContainer } from 'mdb-react-ui-kit'
import { RiMenuLine } from 'react-icons/ri'
import { useDispatch } from 'react-redux'

export default function Navbar() {
  const dispatch = useDispatch()
  return (
    <MDBContainer fluid className='bgMainGradient'>
      <div className='navbar-main'>
        <div className='nav-sec-1'>
        <RiMenuLine onClick={()=>dispatch({type:'toggle'})} className='menuIcon' />
          <img src="https://cashier.shakespeare.vision/static/media/shakespeare_logo_white.c30441c33350e20588c6.png" alt="" />
          <h6>Shakespeare</h6>
        </div>
        <div className="nav-sec-2">
          options
        </div>
      </div>
    </MDBContainer>
  )
}
