import React, { useState } from "react";
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
    MDBTextArea,
    MDBCheckbox,
} from "mdb-react-ui-kit";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useSelector } from "react-redux";
import { createStaff } from "../../../apis/staffRequests";
import { useNavigate } from "react-router-dom";

export default function AddStaffModal({ open, setOpen, restaurant  }) {

    const toggleShow = () => setOpen(!open);
    const [cashier, setCashier] = useState('')
    const {rightsOptions} = useSelector((state)=>state.staff);
    const navigate = useNavigate()
    const [selectedRights, setSelectedRights] = useState([])


    const [data, setData] = useState({
        name: "",
        email: "",
        password:"",
    });
    const [selectedRole, setSelectedRole] = useState("")
    const handleRightChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setSelectedRights([...selectedRights, value]);
        } else {
            setSelectedRights(selectedRights.filter((right) => right !== value));
        }
    };
    const handleRoleChange = (event) => {
        setSelectedRole(event.target.value);
    };
    const changeHandler = (e) => {
        const { name, value } = e.target;
        setData((prevState) => ({ ...prevState, [name]: value }));
    };




    const validateForm = () => {
        if (data.name.trim() === "" ||
            data.email.trim() === "" ||
            data.password.trim() === "" ||
            selectedRole.trim() === "") {
            return false
        }
        return true
    }

    const handleSave = async (e) => {
            e.preventDefault();
            const requestBody={
                ...data,
                role:selectedRole,
                rights:selectedRights
            }
            const res = await createStaff(restaurant._id, requestBody);
            navigate("/restaurants")
    }


    return (
        <>
            <MDBModal show={open} setShow={setOpen} tabIndex="-1">
                <MDBModalDialog size="md" centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Add Staff</MDBModalTitle>
                            <MDBBtn
                                className="btn-close"
                                color="none"
                                onClick={toggleShow}
                            ></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody className="add-restaurant-modal-body">
                            <div className="add-restaurant-modal-body-row">
                                <MDBInput
                                    value={data.name}
                                    name="name"
                                    onChange={changeHandler}
                                    label="Name"
                                    type="text"
                                    size="lg"
                                />
                                <MDBInput
                                    label="Email"
                                    size="lg"
                                    name="email"
                                    onChange={changeHandler}
                                    value={data.email}
                                    type="text"
                                />
                            </div>
                            <div className="add-restaurant-modal-body-row">
                                <MDBInput
                                    value={data.password}
                                    name="password"
                                    onChange={changeHandler}
                                    label="Password"
                                    type="password"
                                />
                                <FormControl size="small" fullWidth>
                                    <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedRole}
                                        name="role"
                                        label="Role"
                                        onChange={handleRoleChange}
                                    >
                                        <MenuItem value={"CASHIER"}>CASHIER</MenuItem>
                                        <MenuItem value={"ADMIN"}>ADMIN</MenuItem>
                                        <MenuItem value={"CAPTAIN"}>CAPTAIN</MenuItem>
                                        <MenuItem value={"KITCHEN"}>KITCHEN</MenuItem>
                                        

                                    </Select>
                                </FormControl>
                            </div>
                            <div className="add-restaurant-modal-body-row">
                                <b>Rights</b>
                            </div>
                            <div className="add-restaurant-modal-body-row">
                            <table style={{ width: "97%", borderCollapse: "collapse", fontSize: ".85rem" }}>
                                   
                                    {rightsOptions.map((r, index1) => {
                                        return (
                                            <tr>
                                                <th style={{ textAlign: "left"}}>{r.label}</th>
                                                {r.fields.map((f, index2) => {
                                                    return (
                                                        <td>
                                                            <div style={{display:"flex", gap:"5px", alignItems:"center"}}>
                                                                <MDBCheckbox onChange={handleRightChange}
                                                                    checked={selectedRights.includes(f.value)}
                                                                    id={index1+"r"+index2}
                                                                    value={f.value} />
                                                                <label htmlFor={index1+"r"+index2} style={{ maxWidth: "155px" }}>{f.label}</label>
                                                            </div>
                                                        </td>)
                                                })}
                                            </tr>
                                        )
                                    })}

                                </table>
                            </div>
                        </MDBModalBody>

                        <MDBModalFooter>
                            <MDBBtn
                                className="rounded-6"
                                color="secondary"
                                onClick={toggleShow}
                            >
                                Close
                            </MDBBtn>
                            <MDBBtn onClick={handleSave} disabled={!validateForm()} className="rounded-6">Save</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
}
