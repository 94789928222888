const initialState={
    rightsOptions :[
        {
          label: "Item Master",
          fields: [
            { label: "Read", value: "Item Master_READ" },
            { label: "Write", value: "Item Master_WRITE" },
          ],
        },
        {
          label: "Tax Configuration",
          fields: [
            { label: "Read", value: "Tax Configuration_READ" },
            { label: "Write", value: "Tax Configuration_WRITE" },
          ],
        },
        {
          label: "Customer Management",
          fields: [
            { label: "Read", value: "Customer Management_READ" },
            { label: "Write", value: "Customer Management_WRITE" },
          ],
        },
        {
          label: "KOT Management",
          fields: [
            { label: "Read", value: "KOT Management_READ" },
            { label: "Write", value: "KOT Management_WRITE" },
          ],
        },
        {
          label: "Reports",
          fields: [{ label: "Yes", value: "Reports_Yes" }],
        },
        {
          label: "Discount Configuration",
          fields: [
            { label: "Read", value: "Discount Configuration_READ" },
            { label: "Write", value: "Discount Configuration_WRITE" },
          ],
        },
        {
          label: "Area Table Management",
          fields: [
            { label: "Read", value: "Area Table Management_READ" },
            { label: "Write", value: "Area Table Management_WRITE" },
          ],
        },
        {
          label: "Allow KOT to Cancel",
          fields: [
            { label: "Reason", value: "Allow KOT to Cancel_Reason" },
            {
              label: "Reason With Password",
              value: "Allow KOT to Cancel_ReasonWithPassword",
            },
          ],
        },
        {
          label: "Allow Bill to Cancel",
          fields: [
            { label: "Reason", value: "Allow Bill to Cancel_Reason" },
            {
              label: "Reason With Password",
              value: "Allow Bill to Cancel_ReasonWithPassword",
            },
          ],
        },
        {
          label: "Category wise Taxes",
          fields: [
            { label: "Read", value: "Category wise Taxes_READ" },
            { label: "Write", value: "Category wise Taxes_WRITE" },
          ],
        },
        {
          label: "Waitlist",
          fields: [
            { label: "Read", value: "Waitlist_READ" },
            { label: "Write", value: "Waitlist_WRITE" },
          ],
        },
      ]
    
}

export default function staffReducer(state=initialState, action){
    switch(action.type){
        default: return state;
    }
}