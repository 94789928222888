import React, { useEffect, useState } from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Sidebar from '../../Components/Sidebar/Sidebar'
import './restaurant.css'
import { MDBBadge, MDBBtn, MDBTable, MDBTableHead, MDBTableBody, MDBContainer, MDBInput } from 'mdb-react-ui-kit';
import AddRestaurantModal from './modal/AddRestaurantModal';
import toast from '../../utils/toast';
import { fetchAllRestaurants } from '../../apis/restaurantRequests';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function Restaurants() {
    const [addRestaurantModalOpen, setAddRestaurantModalOpen] = useState(false)
    const [searchQuery, setSearchQuery] = useState()
    const [restaurants, setRestaurants] = useState([])
    const navigate = useNavigate()

    useEffect(()=>{
        (async function(){
            const {data}= await fetchAllRestaurants()
            setRestaurants([...(data?.restaurants ?? [])])
        })()
    },[])
    const restaurantDummyImg="https://thumbs.dreamstime.com/z/restaurant-building-flat-design-architecture-facade-food-cafe-construction-vector-illustration-69417405.jpg"
    return (
        <>
            <Navbar></Navbar>
            <div className="main-layout">
                <Sidebar></Sidebar>
                <div className="main-body">
                    <MDBContainer fluid>
                        <div className='main-body-container'>
                            <div className='main-body-table-heading'>
                                <h5>Restaurants</h5>
                            </div>
                            <div className='main-body-table-header shadow-5'>
                                <div className="main-body-table-header-sec-1">
                                <MDBInput value={searchQuery} onChange={(e)=>setSearchQuery(e.target.value)} label='Search' id='form1' type='text' />
                                </div>
                                <div className="main-body-table-header-sec-2">
                                <MDBBtn className='primary-btn rounded-6'  onClick={()=>setAddRestaurantModalOpen(true)}>Add Restaurant</MDBBtn>
                                </div>
                            </div>
                            <div className='table-responsive shadow-3'>
                                <MDBTable className='bg-white ' align='middle'>
                                    <MDBTableHead>
                                        <tr>
                                            <th scope='col'>Image</th>
                                            <th scope='col'>Name</th>
                                            <th scope='col'>Opening time</th>
                                            <th scope='col'>Closing Time</th>
                                            <th scope='col'>Menus</th>
                                            <th scope='col'>Orders</th>
                                            {/* <th scope='col'>Actions</th> */}
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {
                                            (restaurants ?? []).
                                            filter((item)=>item.name.match(new RegExp(searchQuery, 'i'))).
                                            map((item, index)=>{
                                                return <tr onClick={()=>navigate("/restaurants/view", {state:item})} className='restaurant-table-row'>
                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        <img
                                                            src={item.image ?? restaurantDummyImg}
                                                            alt=''
                                                            style={{ width: '45px', height: '45px' }}
                                                            className='rounded-circle'
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                      
                                                            <b className='fw-bold mb-1'>{item.name}</b>
                                                            {/* <p className='text-muted mb-0'>john.doe@gmail.com</p> */}
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className='fw-normal mb-1'>{item.openingTime}</p>
                                                    {/* <p className='text-muted mb-0'>IT department</p> */}
                                                </td>
                                                <td>
                                                    {/* <MDBBadge color='success' pill>
                                                        Active
                                                    </MDBBadge> */}
                                                        <p className='fw-normal mb-1'>{item.closingTime}</p>
                                                </td>
                                                <td>
                                                        <p className='fw-normal mb-1'>{item.menu?.length}</p>
                                                </td>
                                                <td>{item.orderCount}</td>
                                                {/* <td>
                                                    <MDBBtn color='link' rounded size='sm'>
                                                        Edit
                                                    </MDBBtn>
                                                </td> */}
                                            </tr>
                                            })
                                        }
                                        {/* <tr>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <img
                                                        src='https://mdbootstrap.com/img/new/avatars/6.jpg'
                                                        alt=''
                                                        style={{ width: '45px', height: '45px' }}
                                                        className='rounded-circle'
                                                    />
                                                    <div className='ms-3'>
                                                        <p className='fw-bold mb-1'>Alex Ray</p>
                                                        <p className='text-muted mb-0'>alex.ray@gmail.com</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <p className='fw-normal mb-1'>Consultant</p>
                                                <p className='text-muted mb-0'>Finance</p>
                                            </td>
                                            <td>
                                                <MDBBadge color='primary' pill>
                                                    Onboarding
                                                </MDBBadge>
                                            </td>
                                            <td>Junior</td>
                                            <td>
                                                <MDBBtn color='link' rounded size='sm'>
                                                    Edit
                                                </MDBBtn>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <img
                                                        src='https://mdbootstrap.com/img/new/avatars/7.jpg'
                                                        alt=''
                                                        style={{ width: '45px', height: '45px' }}
                                                        className='rounded-circle'
                                                    />
                                                    <div className='ms-3'>
                                                        <p className='fw-bold mb-1'>Kate Hunington</p>
                                                        <p className='text-muted mb-0'>kate.hunington@gmail.com</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <p className='fw-normal mb-1'>Designer</p>
                                                <p className='text-muted mb-0'>UI/UX</p>
                                            </td>
                                            <td>
                                                <MDBBadge color='warning' pill>
                                                    Awaiting
                                                </MDBBadge>
                                            </td>
                                            <td>Senior</td>
                                            <td>
                                                <MDBBtn color='link' rounded size='sm'>
                                                    Edit
                                                </MDBBtn>
                                            </td>
                                        </tr> */}
                                    </MDBTableBody>
                                </MDBTable>
                            </div>
                        </div>

                    </MDBContainer>


                </div>
                <AddRestaurantModal
                open={addRestaurantModalOpen}
                setOpen={setAddRestaurantModalOpen}
                setRestaurants={setRestaurants}
                />
                
            </div>
        </>
    )
}
