import React, { useEffect, useState } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBFile,
} from 'mdb-react-ui-kit';
import axios from 'axios';
import { updateRestaurantImage } from '../../../apis/restaurantRequests';
import { useNavigate } from 'react-router-dom';
import toast from '../../../utils/toast';

export default function UpdateRestaurantImage({open, setOpen, image, restaurantId}) {
    const [imageSrc, setImageSrc] = useState('');
	const [imageToUpload, setImageToUpload] = useState(null);
    const navigate= useNavigate()

    const handleUpload = async (e) => {
        e.preventDefault()
		try {
			const res = await updateRestaurantImage(restaurantId, imageToUpload)
            navigate("/restaurants")
            if(!res.err){
                toast.success("Image uploaded successfully");
            }
			setOpen(false);
		} catch (err) {
            console.log(err)
			toast.error(err.response?.data?.message || "Network connection failed");
			setOpen(false);
		}
	};
    useEffect(()=>{
        if(image){
            setImageSrc(image)
        }
	},[image])

  const toggleShow = () => setOpen(!open);

  return (
    <>
      <MDBModal show={open} setShow={setOpen} tabIndex='-1'>
        <MDBModalDialog  centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Update Restaurant Image</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
                <img
                
                src={imageSrc} className='w-100 mb-2' alt="" />
            <MDBFile onChange={(e) => {
                    console.log(e)
                    setImageToUpload(e.target.files[0]);
                    setImageSrc(URL.createObjectURL(e.target.files[0]));
                }} accept='image/*' label={"Choose an Image"} className='' id='customFile' />
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn className='secondary-btn rounded-6' color='secondary' onClick={toggleShow}>
                Close
              </MDBBtn>
              <MDBBtn onClick={handleUpload} className='primary-btn rounded-6'>Update Image</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}