// import {
// 	Alarm,
// 	Circle,
// } from "@mui/icons-material";
import { Badge, Typography } from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import GradientBorder from "../../../Components/GradientBorder/GradientBorder";
import styles from "./TableCell.module.css";


const TableCell = ({ order, table={name:"1"}, justForDisplay, callback }) => {



	return (
		<>
			<span
				style={{
					display:
						false
							? "none"
							: "block",
				}}
			>
				<Badge
					// badgeContent={
					// 	order.isWaiterCalled ? (
					// 		<RecordVoiceOverRounded
					// 			htmlColor="white"
					// 			sx={{
					// 				backgroundColor: "#f44336",
					// 				borderRadius: "50%",
					// 				padding: "4px",
					// 				fontSize: "15px",
					// 			}}
					// 		/>
					// 	) : null
					// }
					sx={{
						"& .MuiBadge-badge": {
							right: "100px",
							top: "8px",
						},
					}}
				>
					<GradientBorder
						gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
						width="1px"
						className={styles.table_cell_wrapper}
					>
						<div
							className={
								styles.table_cell 
								// +
								// (isOverdue ? ` ${styles.overdue}` : "") +
								// (isReserved ? ` ${styles.reserved}` : "") +
								// (" " + styles[order.status])
							}
						>
							
							<div>
								<Typography
									variant="caption"
									sx={{
										fontSize: "0.7rem",
									}}
									component="div"
									// color={isRunning || isReserved ? "inherit" : "transparent"}
								>
									{/* {isReserved ? "Reserved!" : time} */}
								</Typography>
								<Typography
									variant="h4"
									component="div"
									fontWeight={500}
									lineHeight={0.9}
									align="center"
								>
									{table.name ?? "--"}
								</Typography>
								{/* <Typography
									variant="caption"
									sx={{
										fontSize: "0.7rem",
									}}
									component="div"
									color={"black"}
									align="center"
								>
									₹{" "}
									{order.kots?.reduce((acc, kotId) => {
										const kot = kots[kotId];
										console.log(kot, kotId);
										return (kot?.total ?? 0) + acc;
									}, 0) || 0}
								</Typography> */}
							</div>
						</div>

					</GradientBorder>
				</Badge>
			</span>
		</>
	);
};

const AddTableCell = ({ callback }) => {
	const handleClick = () => {
		callback();
	};

	return (
		<div className={styles.add_table_cell}>
			<GradientBorder
				gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
				width="1px"
				className={styles.table_cell_wrapper}
			>
				<div
					className={styles.table_cell}
					onClick={handleClick}
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<div
						style={{
							background:
								"linear-gradient(137.78deg, rgba(255, 139, 22, 0.7) 3.79%, rgba(139, 70, 0, 0.7) 95.3%)",
							boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
							borderRadius: "50%",
							width: "40px",
							height: "40px",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							fontSize: "36px",
							color: "#fff",
							fontWeight: 300,
						}}
					>
						+
					</div>
					<div>
						<Typography
							variant="caption"
							component="div"
							fontWeight={400}
							lineHeight={0.9}
							align="center"
							color="#C2781D"
							sx={{
								textShadow: "0px 4px 6px rgba(0, 0, 0, 0.25)",
								marginTop: "10px",
							}}
						>
							New Table
						</Typography>
					</div>
				</div>
			</GradientBorder>
		</div>
	);
};

export { TableCell as default, AddTableCell };


