import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Sidebar from '../../Components/Sidebar/Sidebar'
import {  MDBContainer, MDBInput } from 'mdb-react-ui-kit';


export default function Dashboard() {
  return (
    <>
      <Navbar></Navbar>
      <div className="main-layout">
        <Sidebar></Sidebar>
        <div className="main-body">
          <MDBContainer fluid>
            <div className='main-body-container'>
              <div className='main-body-table-heading'>
                <h5>Dashboard</h5>
              </div>
              <div className='main-body-table-header'>

              </div>
              <div className='table-responsive'>

              </div>
            </div>
          </MDBContainer>
        </div>
      </div>
    </>
  )
}
