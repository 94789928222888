import { createStore } from "redux";
import { combineReducers } from "redux";
import SidebarReducer from "../Rdeucers/SidebarReducer";
import authReducer from '../Rdeucers/authReducer'
import staffReducer from "../Rdeucers/staffReducer";

const rootReducer = combineReducers({ 
    sidebar: SidebarReducer,
    auth:authReducer,
    staff: staffReducer
 });

export default createStore(rootReducer);
