import React, { useState } from 'react';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
} from 'mdb-react-ui-kit';
import { createTable } from '../../../apis/restaurantRequests';
import { useNavigate } from 'react-router-dom';

export default function AddTableModal({ open, setOpen, section, restaurant, setRestaurants }) {

    const toggleShow = () => setOpen(!open);
    const [table, setTable] = useState("")
    const navigate= useNavigate()

    const handleSave=async ()=>{
        if(table.trim()==="") return;
        const res = await createTable(restaurant._id, table, section );
        if(res.data){
            navigate("/restaurants")
            setOpen(false)
            // setRestaurants((prev)=>{
            //     let allRestaurants = [...prev]
            //     for(let i in prev){
            //         if(item._id===restaurant._id){
            //             item.sections.for
            //         }
            //     }
            // })
        }

    }

    return (
        <>
            <MDBModal show={open} setShow={setOpen} tabIndex='-1'>
                <MDBModalDialog centered size='sm'>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Add Table</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBInput
                                value={table}
                                onChange={(e) => {
                                    setTable((prev)=>{
                                        return e.target.value.toString().length <= 3 ?
                                        e.target.value.toUpperCase() : prev
                                    });
                                }}
                                label="Table Name"
                                type="text"
                            />
                        </MDBModalBody>

                        <MDBModalFooter>
                            <MDBBtn className='secondary-btn rounded-6' color='secondary' onClick={toggleShow}>
                                Close
                            </MDBBtn>
                            <MDBBtn onClick={handleSave} disabled={table.trim() === ""}>Add</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
}