import React, { useState, useContext } from "react";
import styles from "./Login.module.css";
import logo from "../../assets/images/shakespeare_logo.png";
import GradientText from "../../Components/GradientText/GradientText";
import GradientBorder from "../../Components/GradientBorder/GradientBorder";
import axios from "axios";
import toast from "../../utils/toast";
import { useNavigate } from "react-router-dom";
import commonStyles from "../../styles.module.css";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";

const Login = () => {
	const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false)
    const dispatch= useDispatch()

	const handleLogin = async (e) => {
        e.preventDefault()
		setLoading(true)
		if (!email || !password) {
			setLoading(false)
			toast.error("Please enter Email and Password");
			return;
		}

		try {
			const res = await axios.post("/admin/auth/login", {
				email,
				password,
			});
			localStorage.setItem("token", res.data.token);
			axios.defaults.headers.common["x-auth-token"] = res.data.token;
            dispatch({type:"refresh-admin"})

		} catch (error) {
			console.log(error);
            
			if (error.response?.status === 400) {
				toast.error("Invalid Email or Password");
			} else {
				toast.error(error.response?.data?.message ?? "Something went wrong");
			}
		} finally {
			setLoading(false)
		}
	};

	return (
		<div className={styles.body}>
			<form className={styles.form}>
				<img src={logo} alt="Shakespeare Logo" />
				<GradientText
					gradient="linear-gradient(68.16deg, #824500 25.28%, #CA8126 44.43%, #A95800 63.24%)"
					text="Shakespeare"
					style={{ fontSize: "1.8rem", marginBottom: "30px" }}
				/>
				<GradientBorder
					gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
					width="1px"
					className={styles.input_wrapper}
				>
					<input
                    className="w-100"
						type="text"
						placeholder="Email"
						onChange={(e) => setEmail(e.target.value)}
					/>
				</GradientBorder>
				<GradientBorder
					gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
					width="1px"
					className={styles.input_wrapper}
				>
					<input
                    className="w-100"
						type="password"
						placeholder="Password"
						onChange={(e) => setPassword(e.target.value)}
					/>
				</GradientBorder>
				<button
					className={[commonStyles.primary_button, styles.login_button, commonStyles.animate_btn].join(
						" "
					)}
					onClick={handleLogin}
				>
					Sign In
					<CircularProgress color="inherit" sx={{ marginLeft: "10px", display: loading ? "block" : "none" }} size={15} />
				</button>
			</form>
			<footer>
				<a href="https://shakespeare.vision" target="_blank" rel="noreferrer">
					www.shakespeare.vision
				</a>
			</footer>
		</div>
	);
};

export default Login;
